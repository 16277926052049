// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-annulla-iscrizione-jsx": () => import("./../../../src/pages/annulla-iscrizione.jsx" /* webpackChunkName: "component---src-pages-annulla-iscrizione-jsx" */),
  "component---src-pages-come-partecipare-candida-pa-jsx": () => import("./../../../src/pages/come-partecipare/candida-pa.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-candida-pa-jsx" */),
  "component---src-pages-come-partecipare-classifica-pa-jsx": () => import("./../../../src/pages/come-partecipare/classifica-pa.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-classifica-pa-jsx" */),
  "component---src-pages-come-partecipare-crea-profilo-jsx": () => import("./../../../src/pages/come-partecipare/crea-profilo.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-crea-profilo-jsx" */),
  "component---src-pages-come-partecipare-gestire-le-utenze-jsx": () => import("./../../../src/pages/come-partecipare/gestire-le-utenze.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-gestire-le-utenze-jsx" */),
  "component---src-pages-come-partecipare-gestire-progetto-jsx": () => import("./../../../src/pages/come-partecipare/gestire-progetto.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-gestire-progetto-jsx" */),
  "component---src-pages-come-partecipare-inviare-piano-migrazione-jsx": () => import("./../../../src/pages/come-partecipare/inviare-piano-migrazione.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-inviare-piano-migrazione-jsx" */),
  "component---src-pages-come-partecipare-jsx": () => import("./../../../src/pages/come-partecipare.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-jsx" */),
  "component---src-pages-come-partecipare-raggiungere-obiettivi-jsx": () => import("./../../../src/pages/come-partecipare/raggiungere-obiettivi.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-raggiungere-obiettivi-jsx" */),
  "component---src-pages-come-partecipare-ricevi-assistenza-jsx": () => import("./../../../src/pages/come-partecipare/ricevi-assistenza.jsx" /* webpackChunkName: "component---src-pages-come-partecipare-ricevi-assistenza-jsx" */),
  "component---src-pages-conferma-jsx": () => import("./../../../src/pages/conferma.jsx" /* webpackChunkName: "component---src-pages-conferma-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-indirizzo-da-confermare-jsx": () => import("./../../../src/pages/indirizzo-da-confermare.jsx" /* webpackChunkName: "component---src-pages-indirizzo-da-confermare-jsx" */),
  "component---src-pages-informativa-privacy-jsx": () => import("./../../../src/pages/informativa-privacy.jsx" /* webpackChunkName: "component---src-pages-informativa-privacy-jsx" */),
  "component---src-pages-iniziativa-jsx": () => import("./../../../src/pages/iniziativa.jsx" /* webpackChunkName: "component---src-pages-iniziativa-jsx" */),
  "component---src-pages-iniziativa-presentazione-progetti-jsx": () => import("./../../../src/pages/iniziativa/presentazione-progetti.jsx" /* webpackChunkName: "component---src-pages-iniziativa-presentazione-progetti-jsx" */),
  "component---src-pages-iniziativa-soluzioni-standard-jsx": () => import("./../../../src/pages/iniziativa/soluzioni-standard.jsx" /* webpackChunkName: "component---src-pages-iniziativa-soluzioni-standard-jsx" */),
  "component---src-pages-misure-jsx": () => import("./../../../src/pages/misure.jsx" /* webpackChunkName: "component---src-pages-misure-jsx" */),
  "component---src-pages-note-legali-jsx": () => import("./../../../src/pages/note-legali.jsx" /* webpackChunkName: "component---src-pages-note-legali-jsx" */),
  "component---src-pages-opendata-jsx": () => import("./../../../src/pages/opendata.jsx" /* webpackChunkName: "component---src-pages-opendata-jsx" */),
  "component---src-pages-ricevi-aggiornamenti-jsx": () => import("./../../../src/pages/ricevi-aggiornamenti.jsx" /* webpackChunkName: "component---src-pages-ricevi-aggiornamenti-jsx" */),
  "component---src-pages-richiesta-errore-jsx": () => import("./../../../src/pages/richiesta-errore.jsx" /* webpackChunkName: "component---src-pages-richiesta-errore-jsx" */),
  "component---src-pages-richiesta-inviata-jsx": () => import("./../../../src/pages/richiesta-inviata.jsx" /* webpackChunkName: "component---src-pages-richiesta-inviata-jsx" */),
  "component---src-pages-supporto-assistenza-jsx": () => import("./../../../src/pages/supporto/assistenza.jsx" /* webpackChunkName: "component---src-pages-supporto-assistenza-jsx" */),
  "component---src-pages-supporto-domande-frequenti-jsx": () => import("./../../../src/pages/supporto/domande-frequenti.jsx" /* webpackChunkName: "component---src-pages-supporto-domande-frequenti-jsx" */),
  "component---src-pages-supporto-faq-jsx": () => import("./../../../src/pages/supporto/faq.jsx" /* webpackChunkName: "component---src-pages-supporto-faq-jsx" */),
  "component---src-pages-supporto-jsx": () => import("./../../../src/pages/supporto.jsx" /* webpackChunkName: "component---src-pages-supporto-jsx" */),
  "component---src-pages-supporto-materiali-e-risorse-jsx": () => import("./../../../src/pages/supporto/materiali-e-risorse.jsx" /* webpackChunkName: "component---src-pages-supporto-materiali-e-risorse-jsx" */),
  "component---src-pages-team-territoriali-jsx": () => import("./../../../src/pages/team-territoriali.jsx" /* webpackChunkName: "component---src-pages-team-territoriali-jsx" */)
}

